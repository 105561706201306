.officer-card{
    display:flex;
    flex-direction: column;
    row-gap:0;
    align-items: center;
    justify-content: space-between;
  
}

.image-oc{
    max-width: 50%;
    border-radius: 50%;
    border-color: #1B3A5D;
    border-width: thick;
    border-style: solid;
}

.name-oc{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #1B3A5D;
    font-size: 1.3rem;
    text-align: center;
}

.officer-position-oc{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #1B3A5D;
    text-align: center;
    font-size: 0.9rem;
    margin-right: 3%;
}

.description-oc{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #1B3A5D;
    font-size: 0.9rem;
    text-align: center;
    width: 80%;
}

.position-line-oc{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -5%;
  
    width: 100%;

}

.linkedin-navy{
    width: 20px;
    margin-top: 25%;
}

.link-oc{
    /* margin-top: 2%;
    max-width: 12%;
    max-height: 12%;
    margin-left: 2%; */
    /* height: 20px; */
   
   
}

@media screen and (max-width: 550px){


    .description-oc{
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #1B3A5D;
        font-size: 0.5rem;
        text-align: center;
        width: 80%;
    }

    .name-oc{
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        color: #1B3A5D;
        font-size: 1rem;
        text-align: center;
    }
    
    .officer-position-oc{
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        color: #1B3A5D;
        text-align: center;
        font-size: 0.7rem;
        margin-right: 3%;
      
    }

    .linkedin-navy{
        width: 10px;
       
    
    }

}