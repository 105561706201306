.container2 {
   display: grid;
   grid-template-columns:23% 23% 23%;
   grid-template-rows: auto auto auto auto;
  
   column-gap: 0%;
   row-gap: .2%;
   align-items: center, top;
   justify-content: center;
   margin-bottom: 5%;
 }

 .container-div {
   /* padding: 5%;  */
   position:relative;
}

 .header-text{
   font-family: 'League Spartan', sans-serif;
   font-weight: 600;
   font-size: 2.8rem;
   text-align: left;
   color: #1B3A5D;
   margin-left: 18%;
   padding-bottom: 3%;
   padding-top: 3%;
 }

 .item-12 {
  padding-bottom: 5rem;
}

 @media screen and (max-width: 550px){
  .header-text{
    font-family: 'League Spartan', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    text-align: left;
    color: #1B3A5D;
    margin-left: 18%;
    padding-bottom: 3%;
    padding-top: 3%;
  }
}
