

.NavbarItems{
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    
}

.logo{
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: .08rem;
    color: white;
}

.navbar-logo{
    color: white;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(9, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
    align-items: center;   
    margin-left: -40px;
}

.nav-links{
    color: #1B3A5D;;
    text-decoration: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: #F5FCFF;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.icon-links{
    color: white;
    text-decoration: none;
    font-size: 2rem;
    padding: 0.5rem;
    
}


.fa-bars{
    color: #00B1F5;
}

.menu-icon{
    display: none;
}

.logo-flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #F5FCFF;
    border: none;
}

.logo-sphere-styling{
    height: 30px;
}


@media screen and (max-width: 960px){
    .NavbarItems{
        position: relative;
    }
    
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 400px;
        position: absolute;
        top: 6rem;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        row-gap: 0px;
    }

    .nav-menu.active{
        left: 0;
        top: 65px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        height: 300px;
        background-color: #2792deea;

    }

    .nav-links{
        text-align: center;
        padding: 1rem;
        width: 100vw;
        display: table;
    }

    .nav-links:hover{
        background-color: white;
        border-radius: 0;
    }

    .logo-flex{
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(10%, 40%);
        display: flex;
        flex-direction: row;
    }

    .menu-icon{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times{
        color: #00B1F5;;
        font-size: 2rem;
    }

    .logo-sphere-styling{
        height: 50px;
    }

    .icon-links{
        
        /* transform: translate(50%, 20%);
        cursor: pointer; */
        visibility: hidden;
    }

    
    

}
