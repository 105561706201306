.container {
    display: grid;
    grid-template-columns:23% 23% 23%;
    grid-template-rows: auto auto auto auto;
    column-gap: 0%;
    row-gap: .2%;
    align-items: center, top;
    justify-content: center;
    margin-bottom: 10%;
    height: fit-content !important;
    /* overflow: hidden; */
  }

  .mentees-container {
   display: grid;
   grid-template-columns:23% 23% 23%;
   grid-template-rows: auto auto auto auto;
   column-gap: 0%;
   row-gap: .2%;
   align-items: center, top;
   justify-content: center;
   margin-bottom: 30%;
   height: fit-content !important;
   /* overflow: hidden; */
  }

  .navButton{
   background-color: #F5FCFF;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    cursor: pointer;
  }

  .container div {
    /* padding: 5%;  */
    position:relative;
 }

 .mini__nav {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
    padding-right: 1rem;
    /* align-items: top; */
 }

 .header-text1, .header-text3 {
    padding-right: 4rem;
 }

 .header-text1 {
  text-decoration: underline;
 }

 .header-text4 {
  text-decoration: underline;
 }

 .header-text2:hover {
  text-decoration: underline;
 }


 .header-text2, .header-text4 {
  
  float: right;
 }

  .header-text1, .header-text2, .header-text3, .header-text4 {
    font-family: 'League Spartan', sans-serif;
    font-weight: 600;
    font-size: 2.8rem;
    text-align: left;
    color: #1B3A5D;
    margin-left: 18%;
  
  }

  .item-32mentors {
      padding-bottom: 10rem;
  }

  .item-81mentees {
      padding-bottom: 10rem;
      padding: 0px 0px 0px 0px
  }

@media screen and (min-width: 900px) {
   .container {
      font-size: xx-large;
   } 
}

@media screen and (max-width: 550px){

   .header-text1, .header-text2, .header-text3, .header-text4 {
      font-size: 2rem;
    }

   .mentees-container {
      margin-bottom: 60%;
   }


}