.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'League Spartan', sans-serif;
  font-family: 'Montserrat', sans-serif;
  background-color: #F5FCFF;
}

