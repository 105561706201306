.textbody {
    background-color: #F5FCFF;
}

.mission, .pillars, .history {
    margin-left: 15%;
    margin-right: 15%;
    text-align: left;
    color: #1B3A5D;
}

p {
    font-size: 80%;
    font-family: 'Montserrat', sans-serif;
}
    
.pic1, .pic2, .pic3 {
    max-width: 256px;
    max-height: 170.625px;
    padding: 40px;
}

.support, .empowerment, .mentorship {
    max-width: 180px;
    max-height: 180px;
    padding: 0px;
}
    
.missionpics {
    display: flex, inline-block;
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
}


.pillarpics {
    display: flex, inline-block;
    position: relative;
}

.section__header {
    font-family: 'League Spartan';
    color:#1B3A5D;
 
    margin-right: 15%;
    text-align: left;
    font-size:2.15rem;
}


.aboutheader {
    margin-left: 15%;
    margin-right: 15%;
    text-align: left;
    font-family: 'League Spartan';
    color:#1B3A5D;
    font-size:3.75rem;
}
    
.header {
    text-align: left;
    font-family: 'League Spartan';
    color:#1B3A5D
}

