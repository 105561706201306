.wmwe-button{
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #00B1F5;
    background-color: white;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 600;
}

@media screen and (max-width: 550px){

    .wmwe-button{
        font-size: 13px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;

    }

    



}
