@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.format{
    margin-left: 10rem;
    margin-right: 10rem;
    
}

.social-media-img {
    max-width: 400px;
    border-radius: 8px;
    transition: transform 0.2s ease-in-out;
}
  
.social-media-img:hover {
    transform: scale(1.05);
}
  
.instagram-media {
    max-width: 400px;
    margin: 0 auto; 
    border: none; 
  }
  
  .instagram-media a {
    text-decoration: none;
    color: inherit;
  }

.carousel-container {
    width: 100%;
    max-width: 520px; 
    margin: 0 auto;
    align-self: flex-end;
    padding-right: 3%;
  }
  
.carousel-image {
    width: 100%;
    height: auto;
}

  
.subheading1{
    font-family: 'League Spartan', sans-serif;
    font-weight: 600;
    font-size: 28px;
    text-align: left;
    color: #1B3A5D;
    
}

.body1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 17px;
    text-align: left;
    color:#1B3A5D;
    
    margin-top: 1.5rem;
    width: 100%
}

.homepage-image1{ 
    max-width: 80%;
    height: auto;
    float: right;

}

.homepage-image2{ 
    max-width: 100%;
    height: auto;
    float: left;  
}

.layout1{
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
}

.layout2{
    margin-top: 5rem;
}

.textlayout2{
    margin-left: 10rem;
}

.sponsorslayout{
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;
}

.banner1{
    background-color: #00B1F5;
    width: 100%;
    height: 3.5rem;
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.banner-text{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: white;
    padding-right: 75px;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 5vw);
    grid-gap: 15px;
}
.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery__item--1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--3 {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
}

.gallery__item--4 {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
}

.gallery__item--5 {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 5;
}

.section__headers {
    text-align: left;
    font-family: 'League Spartan';
    color: #1B3A5D;
    font-size: 2rem;
}

.homepage-intro{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 6%;
    padding-bottom: 10%;
}

.buttons-div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
    column-gap: normal;
}

.sponsors-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: -3%;
    flex-wrap: wrap;
}

.home-page-intro-image{
    align-self: flex-end;
    max-width: 320px;
    padding-right: 3%;
}

.heading{
    font-size: 60px;
    font-family: 'League Spartan', sans-serif;
    color: #1B3A5D
}

.intro-text{
    width: 55%;
}

.sponsor-img{
    max-width: 175px;
}

.homepage-events{
    visibility: visible;
    max-height: 220px;
}

@media (max-width: 768px) {
    .instagram-media {
      max-width: 250px; /* Smaller size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .instagram-media {
      max-width: 200px; /* Further reduce size */
    }
  }

@media screen and (max-width: 550px){

    .homepage-intro{
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding-top: 6%;
        padding-bottom: 10%;
    }

    .home-page-intro-image{
        align-self: auto;
        max-width: 200px;
    }

    .heading{
        font-size: 30px;
        font-family: 'League Spartan', sans-serif;
        color: #1B3A5D
    }

    .format{
        margin-left: 3rem;
        margin-right: 3rem;
        
    }

    .body1{
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 12px;
        text-align: left;
        color:#1B3A5D;
        width: 30rem;
        margin-top: 2.5rem;
        width: 100%;
        overflow-wrap: break-word;
    }

    .intro-text{
        width: 100%;
    }

    .subheading1{
        font-family: 'League Spartan', sans-serif;
        font-weight: 600;
        font-size: 28px;
        text-align: left;
        color: #1B3A5D;
        overflow-wrap: break-word;
        
    }

    .sponsor-img{
        max-width: 120px;
    }

    .buttons-div{
        column-gap: 5px;
    }

    .banner-text{
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 600;
        color: white;
        padding-right: 0px;
        width: 40%;
    }

    .banner1{
        background-color: #00B1F5;
        width: 100%;
        height: 3.5rem;
        column-gap: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .homepage-events{
        max-height:40px;
        margin-bottom: -10%;
    }
}