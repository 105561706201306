@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

/* Contact.js */

/* to make responsive, media query inline block for big div*/
@media only screen and (max-width: 430px) and (min-width: 360px) {
  .outer-container-contact {
    display: inline-block !important;
    justify-content: center !important;
  }

  /* event block div */
  .outer-container-contact > .containerImage-contact {
    width: 100% !important;
  }

  /* calendar div */
  .outer-container-contact > .containerForm-contact {
    width: 100% !important;
    justify-content: left !important;
    align-items: left !important;
  }

  .photo-contact {
    height: 210px !important;
    width: 310px !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 20% !important;
  }

  .icons-div-contact {
    height: 20% !important;
    width: 100% !important;
    display: flex !important;
    margin-top: 30% !important;
    justify-content: center !important;
  }

  .icons-contact {
    height: 35px !important;
    width: 35px !important;
    justify-content: right !important;
    align-items: right !important;
  }

  .icons-div-contact-left {
    height: 100% !important;
    width: 40% !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }
  
  .icons-div-contact-right {
    height: 100% !important;
    margin-left: 1% !important;
    text-align: left !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-top: 3% !important;
    width: 60% !important;
    margin-left: -3% !important;
  }  

  .link {
    font-family: "Montserrat", sans-serif !important;
    color: #1b3a5d !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    margin-top: -10 !important;
    line-height: 1.6 !important;
    text-decoration: none !important;
    text-align: left !important;
    justify-content: left !important;
    align-items: left !important;
    margin-left: -11% !important;
    width: 300px !important;
  }

  .header-contact {
    color: #1b3a5d !important;
    font-family: "League Spartan" !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    letter-spacing: 0.04em !important;
    margin-bottom: -50 !important;
    line-height: 0.0002em !important;
    justify-content: left !important;
    align-items: left !important;
    text-align: left !important;
    margin-top: 10% !important;
    padding-left: 5% !important;
    width: 300px !important;
  }

  .text-contact {
    font-family: "Montserrat", sans-serif !important;
    color: #1b3a5d !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    margin-top: 8% !important;
    margin-bottom: 8% !important;
    line-height: 0.0002em !important;
    align-items: left !important;
    justify-content: left !important;
    text-align: left !important;
    padding-left: 5% !important;
    width: 300px !important;
  }

  .form-contact {
    height: 55vh !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    text-align: left !important;
    padding-left: 5% !important;
    width: 100% !important;
  }

  .form-contact > h1 {
    margin-bottom: 30px !important;
  }

  .form-contact > input,
  textarea {
    padding: 15px !important;
    border-radius: 10px !important;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
    margin-bottom: 20px !important;
    border: 1px solid rgba(109, 207, 246, 1) !important;
    /* border: none; */
    background: #fff !important;
    font-size: 16px !important;
    color: rgba(109, 207, 246, 1) !important;
    outline: none !important;
    font-family: "Montserrat", sans-serif !important;
    color: #1b3a5d !important;
    font-weight: 500 !important;
    width: 255px !important;
  }

  .form-contact > input:focus,
  textarea:focus {
    border: 1px solid rgba(109, 207, 246, 1) !important;
  }

  .form-contact > textarea {
    height: 30px !important;
    min-height: 70px !important;
    resize: none !important;
    width: 82% !important;
  }

  .form-contact > label {
    padding-bottom: 10px !important;
    color: #1b3a5d !important;
    font-family: "Montserrat", sans-serif !important;
    color: #1b3a5d !important;
    font-weight: 500 !important;
  }

  .form-contact > button {
    padding: 15px !important;
    border: none !important;
    background-color: rgba(109, 207, 246, 1) !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    border-radius: 15px !important;
    color: #fff !important;
    cursor: pointer !important;
    transition: 0.2s ease-in-out !important;
    width: 100px !important;
    height: 50px !important;
    margin-bottom: -20% !important;
  }

  .form-contact > button:hover {
    background-color: rgb(28, 68, 84) !important;
  }
}

/* holds image block and form divs*/
.outer-container-contact {
  display: flex;
  justify-content: center;
  padding-left: 7%;
  padding-right: 7%;
}

/* event block div */
.outer-container-contact > .containerImage-contact {
  width: 50%;
}

/* calendar div */
.outer-container-contact > .containerForm-contact {
  width: 50%;
  justify-content: left;
  align-items: left;
}

.img-div-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: -15%;
}

.photo-contact {
  height: 300px;
  width: 400px;
  padding-right: 5%;
}

.icons-div-contact {
  height: 20%;
  width: 100%;
  display: flex;
  padding-right: 5%;
}

.icons-div-contact-left {
  height: 100%;
  width: 66%;
  flex-direction: column;
  justify-content: right !important;
  align-items: right !important;
  padding-right: -10.5%;
}

.icons-div-contact-right {
  height: 100%;
  width: 40%;
  margin-left: -28%;
  padding-right: -10%;
  text-align: left;
  justify-content: left;
  align-items: left;
  margin-top: 0.5%;
}

.icons-contact {
  height: 35px;
  width: 35px;
  justify-content: right !important;
  align-items: right !important;
  padding-right: -10%;
}

.header-contact {
  color: #1b3a5d;
  font-family: "League Spartan";
  font-weight: 700;
  font-size: 60px;
  letter-spacing: 0.04em;
  margin-bottom: -50;
  line-height: 0.0002em;
  justify-content: left;
  align-items: left;
  text-align: left;
  padding-left: 5%;
}

.text-contact {
  font-family: "Montserrat", sans-serif;
  color: #1b3a5d;
  font-weight: 500;
  font-size: 30px;
  margin-top: 8%;
  margin-bottom: 8%;
  line-height: 0.0002em;
  align-items: left;
  justify-content: left;
  text-align: left;
  padding-left: 5%;
}

.form-contact {
  height: 55vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 5%;
}

.form-contact > h1 {
  margin-bottom: 30px;
}

.form-contact > input,
textarea {
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid rgba(109, 207, 246, 1);
  /* border: none; */
  background: #fff;
  font-size: 16px;
  color: rgba(109, 207, 246, 1);
  outline: none;
  font-family: "Montserrat", sans-serif;
  color: #1b3a5d;
  font-weight: 500;
  width: 75%;
}

.form-contact > input:focus,
textarea:focus {
  border: 1px solid rgba(109, 207, 246, 1);
}

.form-contact > textarea {
  height: 30px;
  min-height: 70px;
  resize: none;
  width: 75%;
}

.form-contact > label {
  padding-bottom: 10px;
  color: #1b3a5d;
  font-family: "Montserrat", sans-serif;
  color: #1b3a5d;
  font-weight: 500;
}

.form-contact > button {
  padding: 15px;
  border: none;
  background-color: rgba(109, 207, 246, 1);
  font-weight: 600;
  font-size: 18px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  width: 100px;
  height: 50px;
}

.form-contact > button:hover {
  background-color: rgba(109, 207, 246, 1);
}

.link {
  font-family: "Montserrat", sans-serif;
  color: #1b3a5d;
  font-weight: 600;
  font-size: 13px;
  margin-top: -10;
  line-height: 1.6;
  text-decoration: none;
  text-align: left;
  justify-content: left;
  align-items: left;
}

/* End of Contact.js */
