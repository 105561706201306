/* updated footer css */

.footerset {
    background-color: #CBF0FF;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    column-gap: 50px;
    padding-bottom: 2%;
    padding-top: 2%;

    color: #1B3A5D;

}

.logo2 {
    max-width: 180px;
    max-height: 106.5px;
    margin-right: 60%;
    margin-top: 5%;

    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    
}

.linkedpages-footer {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.socials-footer {
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;

}

.instagram {
    max-height: 34px;
    max-width: 34px;
    margin-bottom: .1px;
    padding-right: 1px;
}

.linkedin, .facebook {
    max-height: 29px;
    max-width: 29px;
    margin-bottom: 2.5px;
}

.socialpics {
    display: inline-block;
}

.mail {
    max-height: 20px;
    max-width: 20px;
    float: left;
    flex-basis: 70%;
}

.mail-flex{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.link {
    padding-left: 6px;
    max-width: 50px;
}

.mail_link {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.contact-footer{
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-left: -30px;

}

a:link, a:visited  {
    color: #1B3A5D;
    text-decoration: none;
}

a:hover {
    color:#1B3A5D;
    text-decoration: underline;
}

.footer-links{
    color: #1B3A5D;
    
    cursor: pointer;
    background-color: #CBF0FF;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    list-style-type: none 
}

.footer-links-container{

    list-style-type: none 
}

@media screen and (max-width: 550px){

    .socials-footer {
        display: inline-block;
        padding-top: 20px;
        padding-bottom: 0px;
        padding-right: 0px;
    
    }

    .contact-footer{
        display: inline-block;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-right: 0px;
        margin-left: 0px;
    
    }

    .footerset {
        column-gap: 75px;
    }

}